import { useState, useEffect } from 'react';

export interface Post {
  postId: number;
  postTitle: string;
  postText: string;
  dateCreated: {
    date: string;
    time: string;
    value: string;
  };
  files: {
    filename: string;
    url: string;
  }[];
  tags?: string[];
}

export interface ApiResponse {
  status: number;
  message: string;
  name: string;
  postList: Post[];
  allTags?: string[];
}

const extractAndRemoveTags = (
  rawText: string
): { cleanedText: string; extractedTags: string[] } => {
  const tagRegex = /#([a-zA-Z0-9_]+?)#/g; // Matches text enclosed by a pair of `#`

  const extractedTags: string[] = [];
  let cleanedText = rawText;

  let match;
  while ((match = tagRegex.exec(rawText)) !== null) {
    extractedTags.push(match[1]); // Extract the tag (content within `#`)
  }

  // Remove all valid `#example#` patterns from the text
  cleanedText = rawText.replace(tagRegex, '').trim();

  return { cleanedText, extractedTags };
};

const useBlogApi = () => {
  const apiUrl = process.env.REACT_APP_BLOG_API_URL;

  const [data, setData] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      if (!apiUrl) {
        setError('API URL is not defined');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result: ApiResponse = await response.json();
        let allTags: string[] = [];

        const processedPosts = result.postList
          .map((post) => {
            const { cleanedText, extractedTags } = extractAndRemoveTags(
              post.postText
            );

            // Add unique tags to the tags array
            extractedTags.forEach((tag) => {
              if (!allTags.includes(tag)) {
                allTags.push(tag);
              }
            });

            return {
              ...post,
              postText: cleanedText,
              tags: extractedTags,
            };
          })
          .sort(
            (a, b) =>
              new Date(b.dateCreated.value).getTime() -
              new Date(a.dateCreated.value).getTime()
          );

        setData({ ...result, postList: processedPosts, allTags });
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [apiUrl]);

  // Filter posts by selected tags
  const filteredPosts = data?.postList.filter((post) => {
    if (selectedTags.length === 0) {
      return true; // No tags selected, show all posts
    }

    // Check if post includes all selected tags
    return selectedTags.every((tag) => post.tags?.includes(tag));
  });

  return {
    data: filteredPosts ? { ...data, postList: filteredPosts } : null,
    loading,
    error,
    selectedTags,
    setSelectedTags,
  };
};

export default useBlogApi;
