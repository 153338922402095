import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { CapitalizeFirstLeter } from '../../../utils/CapitalizeFirstLetter';
import Constants from '../../../utils/Constants';
import { useEffect } from 'react';
import { scroller } from 'react-scroll';
import scrollToPage from '../../../utils/ScrollToLink';

const BlogPost = () => {
  const { state } = useLocation();
  const { postId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(postId ? postId : '')}`;

    scroller.scrollTo(postId ? postId : '', {
      spy: true,
      smooth: true,
      duration: 500,
    });
  }, []);

  if (!state) {
    return <p>Error: Post data is missing. Please go back and try again.</p>;
  }

  const { postTitle, dateCreated, postText, tags, files } = state;

  return (
    <div id={postId ? postId : ''} className='blog_post'>
      {files?.[0]?.url && (
        <div className='blog_post_hero'>
          <img src={files[0].url} alt={files[0].filename || 'blog post hero'} />
        </div>
      )}
      <div className='blog_post_container container'>
        <h1>{postTitle || 'Untitled Post'}</h1>
        <div
          className='blog_post_container_back opacity_transition_high'
          onClick={() => scrollToPage(Constants.BLOG, location, navigate, true)}
        >
          . . . BACK TO {Constants.BLOG.toLocaleUpperCase()}
        </div>
        <div>{parse(postText)}</div>
        <p>
          <strong>Created:</strong> {dayjs(dateCreated).format('MMMM D, YYYY')}
        </p>
        {tags?.length > 0 && (
          <div>
            <strong>Tags:</strong>{' '}
            {tags.map((tag: string, index: number) => (
              <Link
                key={index}
                to={{
                  pathname: `/blog`,
                }}
                state={{
                  selectedTag: tag, // Pass the selected tag to the Blog page
                }}
                className='tag'
              >
                <button>#{tag}</button>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPost;
