import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import rewardPdf from '../../../../assets/documents/home/000_reward/Terrific ten Special offer.pdf';
import badges from '../../../../assets/imgs/home/10_anniversary/badges_anniversary.png';

const Reward = () => {
  return (
    <div id={Constants.REWARD} className='reward'>
      <div className='reward_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <div className='reward_grid'>
            <ScrollAnimation animateIn='fadeIn' duration={1000} delay={100}>
              <div className='reward_grid_first'>
                <img src={badges} alt='badges anniversary' />
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={150}>
              <div className='reward_grid_second'>
                <h2 className='title_underline_light_small'>
                  TERRIFIC TEN CELEBRATION - CORNARO EXPERIENCE REWARD PROGRAM
                </h2>
                <h3 className='text_center'>
                  Celebrate the decade of excellence with us!
                </h3>
                <a
                  href={rewardPdf}
                  target='_blank'
                  rel='noreferrer'
                  className='opacity_transition_low button'
                >
                  <div>SHOW MORE</div>
                </a>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Reward;
