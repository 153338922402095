import { useLocation, useNavigate } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import logo from '../../assets/imgs/logo_white.svg';
import mapAndDirectionsPdf from '../../assets/documents/home/footer/Conraro_arrival.pdf';
import privacyPolicyPdf from '../../assets/documents/home/footer/Privacy_policy.pdf';
import Constants from '../../utils/Constants';
import Variables from '../../style/variables.scss';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id={Constants.FOOTER} className='footer'>
      <div className='footer_contact container'>
        <div className='footer_contact_text'>
          <div>
            <h3>INFO</h3>
            <p>Address 1: Sinjska ulica 6</p>
            <p>Address 2: Ulica kralja Tomislava 9,</p>
            <p>
              <span>Tel: </span>
              <a href='tel:+385 21 644 200' className='opacity_transition_low'>
                +385 21 644 200
              </a>
            </p>
            <p>
              <span>Mob: </span>
              <a href='tel:+385 91 644 2000' className='opacity_transition_low'>
                +385 91 644 2000
              </a>
            </p>
            <p>
              <span>Front Desk: </span>
              <a
                href='mailto:info@cornarohotel.com'
                className='opacity_transition_low'
              >
                info@cornarohotel.com
              </a>
            </p>
          </div>
          <div>
            <h3>RESERVATIONS</h3>
            <p>
              <span>Tel: </span>
              <a href='tel:+385 21 644 205' className='opacity_transition_low'>
                +385 21 644 205
              </a>
            </p>
            <p>
              <span>Mob: </span>
              <a href='tel:+385 99 544 2005' className='opacity_transition_low'>
                +385 99 544 2005
              </a>
            </p>
            <p>
              <span>Reservations: </span>
              <a
                href='mailto:reservations@cornarohotel.com'
                className='opacity_transition_low'
              >
                reservations@cornarohotel.com
              </a>
            </p>
            <p>
              <span>Booking: </span>
              <a
                href='mailto:booking@cornarohotel.com'
                className='opacity_transition_low'
              >
                booking@cornarohotel.com
              </a>
            </p>
            <p>
              <span>MICE: </span>
              <a
                href='mailto:meetings@cornarohotel.com'
                className='opacity_transition_low'
              >
                meetings@cornarohotel.com
              </a>
            </p>
          </div>
        </div>
        <div className='footer_contact_links'>
          <h3>FOLLOW US</h3>
          <div>
            <a
              href='https://www.facebook.com/cornarohotel'
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='32'
                viewBox='0 0 512 512'
                fill={Variables.secondaryColor}
              >
                <path d='M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z' />
              </svg>
            </a>
            <a
              href='https://twitter.com/cornarohotel'
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='32'
                viewBox='0 0 512 512'
                fill={Variables.secondaryColor}
              >
                <path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z' />
              </svg>
            </a>
            <a
              href='https://www.instagram.com/cornarohotelsplit/'
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='32'
                viewBox='0 0 448 512'
                fill={Variables.secondaryColor}
              >
                <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
              </svg>
            </a>
            <a
              href='https://www.tripadvisor.com/Hotel_Review-g295370-d6871450-Reviews-Cornaro_Hotel-Split_Split_Dalmatia_County_Dalmatia.html'
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='32'
                viewBox='0 0 2304 1408'
              >
                <path
                  fill={Variables.secondaryColor}
                  d='M651 805q0 39-27.5 66.5T558 899q-39 0-66.5-27.5T464 805q0-38 27.5-65.5T558 712q38 0 65.5 27.5T651 805m1154-1q0 39-27.5 66.5T1711 898t-66.5-27.5T1617 804t27.5-66t66.5-27t66.5 27t27.5 66m-1040 1q0-79-56.5-136T572 612t-136.5 56.5T379 805t56.5 136.5T572 998t136.5-56.5T765 805m1153-1q0-80-56.5-136.5T1725 611q-79 0-136 56.5T1532 804t56.5 136.5T1725 997t136.5-56.5T1918 804m-1068 1q0 116-81.5 197.5T572 1084q-116 0-197.5-82T293 805t82-196.5T572 527t196.5 81.5T850 805m1154-1q0 115-81.5 196.5T1725 1082q-115 0-196.5-81.5T1447 804t81.5-196.5T1725 526q116 0 197.5 81.5T2004 804m-964 3q0-191-135.5-326.5T578 345q-125 0-231 62T179 575.5T117 807t62 231.5T347 1207t231 62q191 0 326.5-135.5T1040 807m668-573q-254-111-556-111q-319 0-573 110q117 0 223 45.5T984.5 401t122 183t45.5 223q0-115 43.5-219.5t118-180.5T1491 284t217-50m479 573q0-191-135-326.5T1726 345t-326.5 135.5T1264 807t135.5 326.5T1726 1269t326-135.5T2187 807m-266-566h383q-44 51-75 114.5T2189 470q110 151 110 337q0 156-77 288t-209 208.5t-287 76.5q-133 0-249-56t-196-155q-47 56-129 179q-11-22-53.5-82.5T1024 1168q-80 99-196.5 155.5T578 1380q-155 0-287-76.5T82 1095T5 807q0-186 110-337q-9-51-40-114.5T0 241h365Q514 141 720 84.5T1152 28q224 0 421 56t348 157'
                />
              </svg>
            </a>
            <a
              href='https://www.youtube.com/channel/UCSZTbLA-VbWaI9ijTGN-5Bw'
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='32'
                viewBox='0 0 576 512'
                fill={Variables.secondaryColor}
              >
                <path d='M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z' />
              </svg>
            </a>
          </div>
        </div>
        <div
          className='footer_contact_logo'
          onClick={() => scrollToPage(Constants.HOME, location, navigate)}
        >
          <img src={logo} alt='cornaro footer logo' />
        </div>
      </div>
      <div className='footer_links'>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage(Constants.B2B, location, navigate, true)}
        >
          {Constants.B2B.toUpperCase()}
        </div>
        <div
          className='opacity_transition_high'
          onClick={() =>
            scrollToPage(Constants.SERVICES, location, navigate, true)
          }
        >
          {Constants.SERVICES.toUpperCase()}
        </div>
        <div
          className='opacity_transition_high'
          onClick={() =>
            scrollToPage(Constants.EXPERIENCE, location, navigate, true)
          }
        >
          {Constants.EXPERIENCE.toUpperCase()}
        </div>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage(Constants.BLOG, location, navigate, true)}
        >
          {Constants.BLOG.toUpperCase()}
        </div>
        <div
          className='opacity_transition_high'
          onClick={() =>
            scrollToPage(Constants.IMPRESSUM, location, navigate, true)
          }
        >
          {Constants.IMPRESSUM.toUpperCase()}
        </div>
        <a
          href={mapAndDirectionsPdf}
          className='opacity_transition_high'
          target='_blank'
          rel='noreferrer'
        >
          MAP & DIRECTIONS
        </a>
        <a
          href={privacyPolicyPdf}
          className='opacity_transition_high'
          target='_blank'
          rel='noreferrer'
        >
          PRIVACY POLICY
        </a>
      </div>
      <div className='footer_bottom'>
        <p>Copyright © Cornaro {new Date().getFullYear()}.</p>
      </div>
    </div>
  );
};

export default Footer;
