import Constants from '../../utils/Constants';
import useBlogApi, { Post } from './hooks/useBlogApi';
import dayjs from 'dayjs';
import heroImg from '../../assets/imgs/06_b2b/b2b.webp';
import { useEffect } from 'react';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import { Link, useLocation } from 'react-router-dom';
import { formatUrlString } from '../../utils/FormatUrlString';
import { scroller } from 'react-scroll';

const Blog = () => {
  const { data, selectedTags, setSelectedTags } = useBlogApi();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.BLOG)}`;
  }, []);

  useEffect(() => {
    scroller.scrollTo('filterTags', {
      spy: true,
      smooth: true,
      duration: 500,
    });

    if (state?.selectedTag) {
      setSelectedTags([state.selectedTag]); // Set the passed tag as the selected filter
    }
  }, [state, setSelectedTags]);

  const toggleTag = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  return (
    <div id={Constants.BLOG} className='blog'>
      <div className='blog_hero'>
        <img src={heroImg} alt={`${Constants.BLOG} hero`} />
      </div>
      <div id='filterTags'></div>
      {data ? (
        <div className='blog_container container'>
          <h1 className='text_center'>{data.name}</h1>
          {/* Global Tag Filter */}
          <div id='filterTags' className='blog_container_filter'>
            <strong>Filter by Tags:</strong>
            <div className='blog_container_filter_tags'>
              {data.allTags?.map((tag) => (
                <button
                  key={tag}
                  onClick={() => toggleTag(tag)}
                  className={selectedTags.includes(tag) ? 'active' : ''}
                >
                  #{tag}
                </button>
              ))}
            </div>
          </div>

          {/* Blog Posts */}
          <div className='blog_container_grid'>
            {data.postList.map((post: Post) => (
              <div className='blog_container_grid_card' key={post.postId}>
                <Link
                  to={`/blog/${formatUrlString(post.postTitle)}`}
                  state={{
                    postId: post.postId,
                    postTitle: post.postTitle,
                    dateCreated: post.dateCreated.value,
                    postText: post.postText,
                    tags: post.tags,
                    files: post.files?.map((file) => ({
                      url: file.url,
                      filename: file.filename,
                    })),
                  }}
                >
                  {/* Display Image */}
                  {post.files?.[0]?.url && (
                    <img
                      src={post.files[0].url}
                      alt={post.files[0].filename || 'Post image'}
                    />
                  )}

                  {/* Content */}
                  <div className='blog_container_grid_card_details'>
                    <h3>{post.postTitle || 'Untitled Post'}</h3>
                    <p>
                      Created:{' '}
                      {dayjs(post.dateCreated.value).format('MMMM D, YYYY')}
                    </p>
                  </div>
                </Link>
                <div className='blog_container_grid_card_tags'>
                  {post.tags?.map((tag, index) => (
                    <button
                      key={index}
                      onClick={(e) => {
                        toggleTag(tag);
                        e.stopPropagation();
                      }}
                      className={selectedTags.includes(tag) ? 'active' : ''}
                    >
                      #{tag}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='blog_container container'>
          <h2 className='text_center'>Try again later...</h2>
        </div>
      )}
    </div>
  );
};

export default Blog;
