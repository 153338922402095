import Constants from '../../utils/Constants';
import ScrollAnimation from '../../utils/ScrollAnimation';
import heroImg from '../../assets/imgs/07_services/services.webp';
import ServicesList from './ServicesList';
import { useEffect } from 'react';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';

const Services = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.SERVICES)}`;
  }, []);

  return (
    <div id={Constants.SERVICES} className='services'>
      <div className='services_hero'>
        <img src={heroImg} alt={`${Constants.SERVICES} hero`} />
      </div>
      <div className='services_container container'>
        <h1 className='text_center'>SERVICES</h1>
        <h2 className='text_center'>PERSONAL TOUCH BASED ON AUTHENTICITY</h2>
        {ServicesList.map((service, index) => (
          <ScrollAnimation
            key={index}
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={100}
          >
            <div
              className={
                index % 2 === 0 ? 'services_grid' : 'services_grid_reverse'
              }
            >
              <ScrollAnimation animateIn='fadeIn' delay={100}>
                <div className='services_grid_img'>
                  <img
                    src={service.imgPath}
                    alt={service.imgPath.split('/').pop()?.split('.')[0]}
                  />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' delay={150}>
                <div className='services_grid_description'>
                  {service.description}
                </div>
              </ScrollAnimation>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </div>
  );
};

export default Services;
